.profile-dropzone {
  height: 100%;

  .dz-message {
    height: 100%;
    cursor: pointer;
  }

  .dz-message-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.profile-dropzone-preview {
  width: 70%;
  margin: 0 auto;
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.profile-dropzone-preview-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 15px solid #f5f4f8;

  &:not(.is-busy):hover {

    .profile-dropzone-preview-bg {
      transform: scale(1.05);
      filter: blur(8px);
    }

    .profile-dropzone-clear {
      display: block;
    }
  }

  &.is-busy {

    .profile-dropzone-preview-bg {
      transform: scale(1.05);
      filter: blur(8px);
    }

    .profile-dropzone-busy {
      display: flex;
    }
  }
}

.profile-dropzone-preview-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profile-dropzone-clear {
  border: none;
  padding: 0;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
}

.profile-dropzone-busy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;

  .chase-dot:before {
    background-color: #ffffff;
  }
}