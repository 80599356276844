body[data-sidebar="dark"] .navbar-brand-box {
  background-color: #1D71B8;
  height: 70px;
}

.logo-size-33 {
  width: 33px;
  height: 33px;
}

.main-content {
  min-height: 100vh;
}

.modal-open {
  overflow: hidden;
}

.footer {
  left: 0;
  z-index: 1001;
}

.vertical-menu {
  padding-bottom: 70px;
}

.spinner-chase.sm {
  width: 32px;
  height: 32px;
}

.spinner-chase.dtable {
  // datatable row height - spinner height
  margin-bottom: calc(44.5px - 32px);
}

.is-invalid .select2-selection__control {
  border-color: #f34943 !important;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

#preloader.inner {
  background-color: transparent;
  left: 250px; // width of vertical-menu
}

.card.expand-v {
  height: calc(100% - 24px);
}

.top-heading {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
  font-size: 16px;
}

.auth-user-name {
  vertical-align: middle;
  line-height: 1.2;
}

.auth-user-role {
  display: block;
  font-size: 70%;
}

.password-input {
  background-image: none !important; // hide `invalid` icon that overlaps `show password`

  &.no-fill {
    background-color: white;
  }
}

.show-password {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 10px;
  cursor: pointer;
  z-index: 999;
}

.show-password-icon {
  font-size: 20px;

  &.is-invalid {
    color: $danger;
  }
}

.password-check-list {
  margin-top: 0.25rem;
}

.password-check {
  display: block;
  color: $gray-600;

  &.passed {
    color: $success;
  }

  &.failed {
    color: $danger;
  }
}

.verification-code::-webkit-outer-spin-button,
.verification-code::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.verification-code {
  -moz-appearance: textfield;
  text-align: center;
}

.tfa-switch input[switch="bool"]:not(:checked)+label {
  background-color: #50a5f1;
}